'use strict';

const wishlistBase = require('base_wishlist/wishlist/wishlist');
const tile = require('alimerka/product/tile');

/**
 * @param {Object} $elementAppendTo - The element to append error html to
 * @param {string} msg - The error message
 * display error message if remove item from wishlist failed
 */
function displayErrorMessage($elementAppendTo, msg) {
    if ($('.remove-from-wishlist-messages').length === 0) {
        $elementAppendTo.append(
            '<div class="remove-from-wishlist-messages "></div>'
        );
    }
    $('.remove-from-wishlist-messages')
        .append('<div class="remove-from-wishlist-alert text-center alert-danger">' + msg + '</div>');

    setTimeout(() => {
        $('.remove-from-wishlist-messages').remove();
    }, 3000);
}

function removeWishlist() {
    // delete item account favorite
    $('.js-delete-favorite').off('click');
    $('.js-delete-favorite').on('click', function deleteWishlistItem() {
        const url = $(this).data('url');
        const listToRemove = $(this).closest('.account-ak__right-body-box');
        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success() {
                listToRemove.remove();
                $.spinner().stop();
            },
            error() {
                $.spinner().stop();
            }
        });
    });
}

function modifyWishlistName() {
    $('[contenteditable]').off('focusout');
    $('[contenteditable]').on('focusout', function editName() {
        var url = $(this).data('url');
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            data: {
                name: $(this).text().trim()
            },
            success: () => {
                $.spinner().stop();
            }
        });
    });
    $('.wishlist-edit-name').click(function focusInputName() {
        $(this).prev('[contenteditable]').focus();
    });
}

wishlistBase.removeFromWishlist = () => {
    $('body').on('click', '.remove-from-wishlist', function removeListener(e) {
        e.preventDefault();
        var listID = $('.js-datalist-id').data('list'); // eslint-disable-line vars-on-top
        var url = $(this).data('url') + '&listId=' + listID; // eslint-disable-line vars-on-top
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'html',
            data: {},
            success: (response) => {
                $(this).closest('.js-product-card').remove();
                $('.js-list-count').empty();
                $('.js-list-count').html(JSON.parse(response).listCountLabel);
            }
        });
    });
};

wishlistBase.addNewWishlist = () => {
    $('body').on('click', '.new-wishlist', function clickListener(e) {
        e.preventDefault();
        $('body').trigger('before:createWishlist');
        var url = $(this).data('url'); // eslint-disable-line vars-on-top

        $('.wishlist-container').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'html',
            data: {},
            success: (response) => {
                $('.wishlist-container').spinner().stop();
                const allLists = $('.all-wishlist-container');
                $(allLists).before(JSON.parse(response).rendered);
                removeWishlist();
                modifyWishlistName();
                if (JSON.parse(response).renderedListWithProduct) {
                    $('body').trigger('after:createWishlist', { createListUrl: JSON.parse(response).createListUrl, renderedListWithProduct: JSON.parse(response).renderedListWithProduct });
                }
            },
            error: () => {
                var $elToAppend = $('.wishlist-container');
                $elToAppend.spinner().stop();
                displayErrorMessage($elToAppend, $elToAppend.data('error-msg'));
            }
        });
    });
};

wishlistBase.addAllToCartFromWishlist = () => {
    $('body').on('click', '.add-all-to-cart', function click(e) {
        e.preventDefault();
        var url = $(this).attr('href'); // eslint-disable-line vars-on-top
        $.ajax({
            url: url,
            method: 'POST',
            dataType: 'json',
            success(response) {
                if (response.status) {
                    $('.minicart').trigger('count:update', response.cart);
                    $('body').trigger('product:afterAddToCart', response);
                    $('.minicart-total span[data-subtotal]').text(response.cart.totals.grandTotal);
                    tile.updateProductAddedIcons();
                }
            }
        });
    });
};

wishlistBase.openShareListModal = () => {
    $('body').on('click', '.js-open-sharelist-modal', (e) => {
        e.preventDefault();
        $('#listid-view').val(e.currentTarget.dataset.listid);
        $('#share-form-dni-view').val('');
        $('#dniHelp').css('display', '');
    });
};

wishlistBase.submitShareList = () => {
    $('form[name="dwfrm_shareList"]').on('submit', function (e) {
        const form = $(this);
        if (!form) return;
        e.preventDefault();

        const formData = form.serializeArray();
        $.ajax({
            url: form.attr('action'),
            method: 'POST',
            dataType: 'json',
            data: formData,
            success: (response) => {
                if (response.error) {
                    $('#dniHelp').text(response.error);
                    $('#dniHelp').css('display', 'block');
                } else {
                    $(this).closest('#sharelistmodal').fadeOut();
                }
            },
            error: () => {
                $(this).closest('#sharelistmodal').fadeOut();
            }
        });
    });
};

wishlistBase.updateWishlistQuantity = () => {
    $('body').on('wishlist:updateQuantity', function (e, data) {
        const url = $('.js-datalist-id').data('url');
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: data,
            complete: () => {
                $.spinner().stop();
            }
        });
    });
};

window.onload = () => {
    $.spinner().start();
    $('.js-product-card').each((index, itemProduct) => {
        let pid = $(itemProduct).find('.product').data('pid');
        let quantity = parseFloat(itemProduct.dataset.quantity);
        $('#itemquantity-' + pid).val(parseFloat(quantity, 10));
    });
    $.spinner().stop();
};

wishlistBase.modifyWishlistName = modifyWishlistName;
wishlistBase.removeWishlist = removeWishlist;

module.exports = wishlistBase;
